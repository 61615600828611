import React from 'react';
import { Box, CardContent, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardComponent from '../../components/CardComponent/CardComponent';
import './Projects.css';


const Projects: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Box className="project-bg-image" sx={{ padding: 3 }}>
            <Grid container spacing={3} className='project-container'>
                <Grid item xs={12} md={8}>
                    <CardComponent
                        title={t('projects.myWebsiteTitle')}
                        items={t('projects.myWebsiteContent', { returnObjects: true })}
                    />
                    <CardComponent
                        title={t('projects.chatbotTitle')}
                        items={t('projects.chatbotContent', { returnObjects: true })}
                        link='chatbot'
                    />
                    <CardComponent
                        title={t('projects.BRITitle')}
                        items={t('projects.BRIContent', { returnObjects: true })}
                        link='bribot'
                    />
                </Grid>
            </Grid>
        </Box>
    );
}


export default Projects;