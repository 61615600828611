import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Projects from './pages/Projects/Projects';
import Profile from './pages/Profile/Profile';
import Chatbot from './pages/Chatbot/Chatbot';
import BRIbot from './pages/BRIbot/BRIbot';
import Footer from './components/Footer/Footer';
import theme from './Theme';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Header from './components/Header/Header';
import { BrowserRouter } from 'react-router-dom';


const App: React.FC = () => {
  return (
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
              <div className="app-container">
                  <header className="app-header">
                      <Header />
                  </header>
                  <main className="app-content">
                      <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/profile" element={<Profile />} />
                          <Route path="/projects" element={<Projects />} />
                          {/*<Route path="/projects/my-website" element={<MyWebsiteProject />} />*/}
                          <Route path="/projects/chatbot" element={<Chatbot />} />
                          <Route path="/projects/bribot" element={<BRIbot />} />
                      </Routes>
                  </main>
                  {/* <Footer /> */}
              </div>
          </BrowserRouter>
      </ThemeProvider>
  );
};


export default App;
