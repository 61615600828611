import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, TextField, Button, Typography, List, ListItem, ListItemText, useTheme } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


import Disclaimer from '../../components/Disclaimer/Disclaimer';
import { useTranslation } from 'react-i18next';
import './BRIbot.css';

interface Message {
  type: 'user' | 'bot' | 'nudge';
  text: string;
  content?: JSX.Element; // Optional, only for special messages like the LinkedIn nudge
}


const dummyMessages: Message[] = [
    { type: 'bot', text: "Hello! I'm a chatbot, how can I help you?" },
    { type: 'user', text: "I'd like to know more about your projects" },
    { type: 'bot', text: "Sure! I have a few projects that I'd love to share with you. You can find more information about them on my projects page." },
    { type: 'bot', text: "I also have a LinkedIn page where I share my professional experiences and connect with other professionals. You can find the link to my LinkedIn page on my profile page." },
    { type: 'bot', text: "Is there anything else you'd like to know?" },
    ];



const BRIbot: React.FC = () => {
    const theme = useTheme();
    const [disclaimerOpen, setDisclaimerOpen] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [sessionId, setSessionId] = useState<string>(''); 

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
        const disclaimerAcknowledged = localStorage.getItem('disclaimerBRIAcknowledged');
        if (!disclaimerAcknowledged  || disclaimerAcknowledged === 'false') {
            setDisclaimerOpen(true);
        }
    }, []);

    useEffect(() => {
        const messageListElement = document.querySelector('.message-list');
        if (messageListElement) {
            messageListElement.scrollTop = messageListElement.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        // Automatically focus the TextAreaAutosize when the component mounts
        if (textAreaRef.current) {
          textAreaRef.current?.focus();
        }
      }, []);

    const closeDisclaimer = () => {
        localStorage.setItem('disclaimerBRIAcknowledged', 'true')
        setDisclaimerOpen(false);
    };

    const handleOpenDisclaimer = () => {
        localStorage.setItem('disclaimerBRIAcknowledged', 'false')
        setDisclaimerOpen(true);
      };



    const handleSendMessage = async() => {
        if (!currentMessage.trim() || isLoading) return; // Prevent sending empty messages or when already loading
    
        // Immediately add the user's message to the chat
        const messageToSend = currentMessage;
        setCurrentMessage(''); // Clear input field
        setMessages(prevMessages => [...prevMessages, { type: 'user', text: messageToSend }, { type: 'bot', text: "..." }]);
        setIsLoading(true); // Start loading simulation
        
        const messagePayload = { 
            message: currentMessage,
            session_id: sessionId
        };

        try {
            // Print the message to the console
            console.log(messageToSend);
            const response = await fetch('https://us-west1-myworld-406618.cloudfunctions.net/gemini-bri', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(messagePayload),
            });

        
            if (response.ok) {
                const responseData = await response.json(); // Get the response data
                console.log(responseData.response); // Correctly log the response
                // Use the 'response' field from the response JSON object
                setMessages(prev => [...prev.slice(0, -1), { type: 'bot', text: responseData.response }]);
                setSessionId(responseData.session_id);
            } else {
                console.error('Failed to send message');
                
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages(prev => [...prev.slice(0, -1), { type: 'bot', text: "I'm sorry, I'm having trouble processing your request. Please try again." }]);
        } finally {
            setIsLoading(false); // Re-enable the Send button
        }
        const userMessageCount = messages.filter(msg => msg.type === 'user').length + 1; // +1 for the current message
        if (userMessageCount === 5) { 

        setTimeout(() => {
            // Create a LinkedIn button as a special message
            const linkedInMessage: Message = {
                type: 'nudge',
                text: t('chatbot.linkedinNudge'), // Add a translation key for your nudge message
                content: (
                <Button startIcon={<LinkedInIcon />} href='https://www.linkedin.com/in/georges-taverne/' target="_blank" variant="contained" className='contact-button'>
                    LinkedIn
                </Button>
                ),
            };
            setMessages(prev => [...prev, linkedInMessage]);
            }, 1000);
        }
      };

    return (
        <Box className="bribot-bg-image">
        {disclaimerOpen && (
                <Disclaimer
                open={disclaimerOpen}
                onClose={closeDisclaimer}
                storageKey="BRIBotDisclaimer"
                title={t('disclaimerBRI.title')}
                content={t('disclaimerBRI.content')}
                goal={t('disclaimerBRI.goal')}
                acknowledgeText={t('disclaimerBRI.acknowledge')}
                cancelText={t('disclaimerBRI.cancel')}
                logo={`${process.env.PUBLIC_URL}/bri-logo.png`}
                link='https://www.resilienceindex.org/about'
                />
            )}          
            <Container className="chat-frame" sx={{bgcolor: theme.palette.background.paper,}}>
            <div className="chatbot-header">
                <Typography variant="h4" component="h1">Building Resiliency Index</Typography>
                <Button onClick={handleOpenDisclaimer} variant="contained" color="primary">
                    {t('chatbot.showDisclaimer')}
                </Button>
            </div>
            <List className="message-list">
            {
                messages.map((msg, index) => (
                    <ListItem key={index} className={`message-item ${msg.type}-message`}>
                    <Box className="message-bubble">
                        {msg.type === 'nudge' ? (
                        <>
                            <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>{msg.text}</Typography>
                            {msg.content}
                        </>
                        ) : (
                        <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>{msg.text}</Typography>
                        )}
                    </Box>
                    </ListItem>
                ))
            }
            </List>
            <Box className="input-container">
              <TextareaAutosize
                className="textarea-autosize"
                ref={textAreaRef} // Apply the ref here
                minRows={3}
                maxRows={4}
                value={currentMessage}
                onChange={(e) => setCurrentMessage(e.target.value)}
                placeholder={t('chatbot.typeMessage')}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey && !isLoading) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                />
                <Button variant="contained" color="primary" onClick={handleSendMessage} disabled={isLoading}>
                  {isLoading ? 'Loading...' : t('chatbot.send')}
                </Button>
              </Box>
          </Container>
        </Box>
      );
    };
    

export default BRIbot;
