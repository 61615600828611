import React from 'react';
import { Card, CardContent, Typography, CardActionArea } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';


interface CardComponentProps {
    title: string;
    items: string[];
    link?: string;
}

const CardComponent: React.FC<CardComponentProps> = ({ title, items, link }) => {
  const cardContent = (
      <CardContent>
          <Typography gutterBottom variant="h5">{title}</Typography>
          {items.map((item, index) => (
              <Typography key={index} variant="body2">{item}</Typography>
          ))}
      </CardContent>
  );

  return (
      <Card elevation={3} sx={{ opacity: 0.95, backgroundColor: 'rgba(255, 255, 255, 0.95)' }}>
          {link ? (
              <CardActionArea component={RouterLink} to={link}>
                  {cardContent}
              </CardActionArea>
          ) : (
              cardContent
          )}
      </Card>
  );
};

export default CardComponent;