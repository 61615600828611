import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Button, Drawer, IconButton, List, ListItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import './Header.css';
import styled from '@mui/system/styled';
import { useMediaQuery } from '@mui/material';
import theme from '../../Theme';


const StyledIcon = styled('img')(({ theme }) => ({
    marginRight: theme.spacing(1),
    height: '50px' // Adjust the height as needed
  }));
  

const Header: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { t } = useTranslation();

    const handleDrawerOpen = () => setDrawerOpen(true);
    const handleDrawerClose = () => setDrawerOpen(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const DrawerContent = () => (
        <List>
            <ListItem component={Link} to="/projects" onClick={handleDrawerClose} className='drawer-link'>
                {t('myProjects')}
            </ListItem>
            <ListItem component={Link} to="/profile" onClick={handleDrawerClose} className='drawer-link'>
                {t('myProfile')}
            </ListItem>
            <ListItem className='drawer-link'>  
                <LanguageSwitcher />
            </ListItem>
        </List>
    );

    return (
        <AppBar position="static" className={isMobile ? "mobile-header" : "desktop-header"}>
            <Toolbar>
                {isMobile ? (
                    <>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer 
                            anchor="left" 
                            open={drawerOpen} 
                            onClose={handleDrawerClose} 
                            PaperProps={{
                                style: { backgroundColor: theme.palette.primary.main},
                            }}
                        >
                            <DrawerContent />
                        </Drawer>
                        <StyledIcon src={`${process.env.PUBLIC_URL}/my-world.png`} alt="My World" onClick={() => window.location.href = '/'} />
                        <Typography variant="h6" className="header-title" onClick={() => window.location.href = '/'}>
                            {t('mySite')}
                        </Typography>
                    </>
                ) : (
                    <>
                        <StyledIcon src={`${process.env.PUBLIC_URL}/my-world.png`} alt="My World" onClick={() => window.location.href = '/'} />
                        <div className="header-links">
                            <Button color="inherit" component={Link} to="/">
                                <Typography variant="h6" className="header-title" onClick={() => window.location.href = '/'}>
                                    {t('mySite')}
                                </Typography>
                            </Button>
                            <Button color="inherit" component={Link} to="/projects">
                                <Typography variant="h6" className="header-title">
                                    {t('myProjects')}
                                </Typography>
                            </Button>
                            <Button color="inherit" component={Link} to="/profile">
                                <Typography variant="h6" className="header-title">
                                    {t('myProfile')}
                                </Typography>
                            </Button>
                        </div>
                        <div className='language-switcher'>
                            <LanguageSwitcher />
                        </div>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;


// return (
//     <AppBar position="static">
//         <Toolbar className='header'>
//             {isMobile && (
//                 <IconButton
//                     edge="start"
//                     color="inherit"
//                     aria-label="menu"
//                     onClick={handleDrawerOpen}
//                     className="menu-button"
//                 >
//                     <MenuIcon />
//                 </IconButton>
//             )}
//             <StyledIcon
//                 src={`${process.env.PUBLIC_URL}/my-world.png`}
//                 alt="My World"
//                 onClick={() => window.location.href = '/'}
//             />
//             <Typography variant="h6" className="header-title" onClick={() => window.location.href = '/'}>
//                  {t('mySite')}
//              </Typography>
//             {!isMobile && (
//                 <div className="header-links">
//                     <Button color="inherit" component={Link} to="/projects">
//                         {t('myProjects')}
//                     </Button>
//                     <Button color="inherit" component={Link} to="/profile">
//                         {t('myProfile')}
//                     </Button>
//                     <div className='language-switcher'>
//                         <LanguageSwitcher />
//                     </div>
//                 </div>
//             )}
//             <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
//                 <DrawerContent />
//                 <LanguageSwitcher />
//             </Drawer>
//         </Toolbar>
//     </AppBar>
// );
