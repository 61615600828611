import React, { useState } from 'react';
import i18n from 'i18next';
import { IconButton, Menu, MenuItem } from '@mui/material';
import './LanguageSwitcher.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';


const LanguageSwitcher: React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        handleClose();
    };

    return (
        <>
            <IconButton onClick={handleClick} color="inherit" className="language-switcher-icon">
                <FontAwesomeIcon icon={faLanguage} />
            </IconButton>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => changeLanguage('en')}
                    selected={i18n.language === 'en'}
                    className={i18n.language === 'en' ? 'selectedLanguage' : ''}
                >
                    English
                </MenuItem>
                <MenuItem
                    onClick={() => changeLanguage('fr')}
                    selected={i18n.language === 'fr'}
                    className={i18n.language === 'fr' ? 'selectedLanguage' : ''}
                >
                    Français
                </MenuItem>
                <MenuItem
                    onClick={() => changeLanguage('es')}
                    selected={i18n.language === 'es'}
                    className={i18n.language === 'es' ? 'selectedLanguage' : ''}
                >
                    Español
                </MenuItem>
            </Menu>
        </>
    );
};

export default LanguageSwitcher;

