import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import { Box, Grid, Card, CardContent, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardComponent from '../../components/CardComponent/CardComponent';
import './Profile.css';

  
const Profile: React.FC = () => {
    const { t } = useTranslation();

    const linkedInUrl = 'https://www.linkedin.com/in/georges-taverne/';
    const gitHubUrl = 'https://github.com/Gtaverne';
    const email = 'georges.taverne@gmail.com'; 


    return (
        <Box className="profile-bg-image" sx={{ padding: 3 }}>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={8}>
                <CardComponent
                    title={t('profile.skillsTitle')}
                    items={t('profile.skillsContent', { returnObjects: true })}
                />
                <CardComponent
                    title={t('profile.experiencesTitle')}
                    items={t('profile.experiencesContent', { returnObjects: true })}
                />
                <CardComponent
                    title={t('profile.educationTitle')}
                    items={t('profile.educationContent', { returnObjects: true })}
                />
                <Card sx={{ marginTop: 2, opacity: 0.95, backgroundColor: 'rgba(255, 255, 255, 0.95)' }}>
                        <CardContent>
                        <Typography gutterBottom variant="h5">{t('profile.contactMe')}</Typography>

                            <Grid container spacing={4} justifyContent="center" >
                                {/* <Grid item>
                                    <Button startIcon={<EmailIcon />} href={`mailto:${email}`} variant="contained" className='contact-button'>
                                        E-mail
                                    </Button>
                                </Grid> */}
                                <Grid item>
                                    <Button startIcon={<LinkedInIcon />} href={linkedInUrl} target="_blank" variant="contained" className='contact-button'>
                                        LinkedIn
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button startIcon={<GitHubIcon />} href={gitHubUrl} target="_blank" variant="contained" className='contact-button'>
                                        GitHub
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Profile;
