import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
          main: '#0D1B2A',
    
        },
        secondary: {
          main: '#F95738',
        },
        background: {
            default: '#FFFFFF',
            paper: 'rgba(255, 255, 255, 0.9)'
        },
    },
})

theme.components = {
        MuiAppBar: {
            styleOverrides: {
                root: ({ palette }) => ({
                    backgroundColor:  theme.palette.primary.main
                }),
            },
        },
        MuiLink: {
            styleOverrides: {
                root: ({ palette }) => ({
                    color:  theme.palette.primary.main
                }),
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: ({ palette }) => ({
                    '&.Mui-selected': { // Increase specificity
                        backgroundColor:  theme.palette.primary.main,
                        color: 'white !important'
                    },
                }),
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    marginBottom: '20px', // Space between cards
                    // maxWidth: '600px', // Adjust width as needed
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Optional shadow for depth
                    display: 'flex', // Use flexbox for alignment
                    flexDirection: 'column', // Stack children vertically
                    justifyContent: 'flex-start',  
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: { 
                    color: theme.palette.secondary.main,
                    marginBottom: '10px', // Spacing after title
                },
                h2: { 
                    color: theme.palette.secondary.main,
                    marginBottom: '10px', // Spacing after title
                },
                h3: { 
                    color: theme.palette.secondary.main,
                    marginBottom: '10px', // Spacing after title
                },
                h4: { 
                    color: theme.palette.secondary.main,
                    marginBottom: '10px', // Spacing after title
                },
                h5: { // Assuming you are using variant='h5' for the card titles
                    color: theme.palette.secondary.main,
                    marginBottom: '10px', // Spacing after title
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    // backgroundColor: '#F95738', // Secondary color for the background
                    color: '#FFFFFF', // White text
                    // Add any other styles for the header
                },
            },
        },
        MuiGrid: {  
            styleOverrides: {
                container: {
                    display: 'flex', // Ensure it's a flex container
                    justifyContent: 'center', // Center content horizontally
                    width: '100%', // Ensure the card takes full width
                },
                // item: {
                //     justifyContent: 'center', // Center content horizontally
                //     width: '100%', // Ensure the card takes full width
                // },
                // root: {
                //     display: 'flex', // Ensure it's a flex container
                //     flexDirection: 'column', // Stack children vertically
                //     justifyContent: 'center', // Center content horizontally
                // },
            }
        },
        
    }


export default theme;