import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './Disclaimer.css';

interface DisclaimerProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content: string;
  goal: string;
  acknowledgeText: string;
  cancelText: string;
  logo?: string;  // Logo to display in the disclaimer
  link?: string; 
  storageKey: string;  // Key for storing acknowledgment status
}

const Disclaimer: React.FC<DisclaimerProps> = ({
  open, onClose, storageKey, title, content, goal, acknowledgeText, cancelText, logo, link
}) => {
  const navigate = useNavigate();

  const handleDisclaimerClose = () => {
    localStorage.setItem(storageKey, 'true');
    onClose();
  };

  const handleCancel = () => {
    navigate('/projects');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        {logo && (
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          {link ? (
            <Link href={link} target="_blank" rel="noopener noreferrer">
              <img src={logo} alt="Logo" style={{ maxWidth: '200px' }} />
            </Link>
          ) : (
            <img src={logo} alt="Logo" style={{ maxWidth: '200px' }} />
          )}
        </div>
      )}
        <DialogContentText>{goal}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDisclaimerClose} color="primary">
          {acknowledgeText}
        </Button>
        <Button onClick={handleCancel} color="secondary">
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Disclaimer;