// Home.tsx
import React from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import './Home.css';
import { useTranslation } from 'react-i18next';


const Home: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Box className="home-bg-image"> {/* Remove any inline sx styling here */}
            <Card sx={{ maxWidth: 600, opacity: 0.95, backgroundColor: 'rgba(255, 255, 255, 0.95)' }}>
                <CardContent>
                    <Typography variant="h4" component="h1" gutterBottom>
                        {t('home.welcome')}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        
                        <br />
                        {t('home.discover')}
                    </Typography>
                    <Button variant="contained" color="primary" component={Link} to="/projects">
                        {t('home.viewProjects')}
                    </Button>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Home;