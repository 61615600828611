import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // Load translation using http backend
  .use(Backend)
  // Detect user language
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Init i18next
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false, // Not needed for React
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  }, (error: any, t: (arg0: string) => any) => {
    if (error) console.log('i18n initialization error:', error);
  });

export default i18n;
